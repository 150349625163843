<template>
  <div class="content-wrapper my-3 mx-1">
    <div v-if="tmpItem && tmpItem.id" class="d-flex justify-content-between">
      <h4>
        {{ tmpItem.titolo || tmpItem.codice }}
      </h4>
      <!-- <div style="color: gray" class="d-flex flex-column">
        <span> Dossier: {{ dossier.codice }} </span>
        <span> {{ dossier.titolo }} - {{ dossier.Company }} </span>
      </div> -->
    </div>
    <h4 v-else>Nuovo ordine di servizio</h4>

    <b-row v-if="dossierName" class="mb-4">
      <b-col>
        <span
          >Dossier:
          <span style="font-weight: bold;">{{ dossierName }}</span></span
        >
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col>
        <b-form-checkbox v-model="tmpItem.confermato">
          Confermato
        </b-form-checkbox>
      </b-col>
    </b-row>

    <!-- <b-row>
          <b-col>
            <b-form-group label="Codice">
              <b-form-input v-model="tmpItem.codice" />
            </b-form-group>
          </b-col>
        </b-row> -->

    <b-row class="mb-2">
      <b-col>
        <b-form-group label="Titolo">
          <b-form-input v-model="tmpItem.titolo" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col>
        <label class="mr-2">CIE Assegnato</label>
        <b-form-select
          v-model="tmpItem.idCie"
          :options="CieOptions"
          @change="selectCie(cie)"
        >
        </b-form-select>
        <!-- <b-dropdown :text="tmpItem.Cie" v-model="tmpItem.idCie">
              <b-dropdown-item
                @click="selectCie(cie)"
                v-for="cie in CieOptions"
                :key="cie.value"
                >{{ cie.text }}
              </b-dropdown-item>
            </b-dropdown> -->
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Luogo inizio Servizio">
          <!-- <vue-google-autocomplete
            ref="address"
            id="map"
            classname="form-control"
            placeholder="Please type your address"
            v-on:placechanged="getAddressData"
            country="sg"
          >
          </vue-google-autocomplete> -->
          <!-- <b-form-input v-model="tmpItem.luogo" ref="inputAutocomplete" /> -->
          <GmapMap
            ref="gmapMap"
            :center="{ lat: 41.866643219519844, lng: 12.783763679478184 }"
            :zoom="5"
            map-type-id="roadmap"
            style="width: 100%; height: 400px"
            @rightclick="addMarker"
          >
            <gmap-custom-marker
              :key="index"
              v-for="(marker, index) in markers"
              :marker="marker"
              alignment="top"
            >
              <img
                src="@/assets/images/gmapMarker_Axerta.svg"
                style="width:30px"
              />
            </gmap-custom-marker>
            <!-- <GmapMarker
              ref="gmapMarker"
              :key="index"
              v-for="(m, index) in markers"
              :position="m.latLng"
              :clickable="true"
              :draggable="false"
              v-model="m.showWindow"
            /> -->
          </GmapMap>
        </b-form-group>
      </b-col>
      <!-- <b-col>
        <b-form-group label="Link Maps">
          <b-form-input v-model="tmpItem.linkMaps" />
        </b-form-group>
      </b-col> -->
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Data ora suggerita">
          <b-row>
            <b-col>
              <b-form-datepicker
                v-model="tmpItem.dataOra_inizio"
                :min="new Date()"
                locale="it"
              ></b-form-datepicker>
            </b-col>
            <b-col>
              <b-form-timepicker
                v-model="tmpItem.ora_suggerita"
                locale="it"
              ></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Durata Stimata (giorni)">
          <b-form-input type="number" v-model="tmpItem.durataStimata" />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- <b-row v-if="tmpItem.elementi">
      <b-col>
        <b-form-group label="Elementi Dossier a supporto">
          <TagsInput
            v-model="elementiSelezionati"
            :tagsList="dossier.elementi"
          />
        </b-form-group>
      </b-col>
    </b-row> -->

    <b-row>
      <b-col>
        <b-form-group label="Descrizione Ordine di Servizio">
          <b-form-textarea
            v-model="tmpItem.annotazioni"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Numero CIE assegnati">
          <b-form-input type="number" v-model="tmpItem.numeroCie" />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="mt-5 mb-5">
      <!-- <b-button v-if="tmpItem.confermato" disabled squared variant="outline-primary" size="lg" class="big-button">
            <b-icon-check></b-icon-check> Confermato
          </b-button> -->
      <b-button
        squared
        :disabled="loading"
        variant="primary"
        size="lg"
        class="big-button"
        @click="saveItem"
      >
        {{ !loading ? "Salva" : "Loading..." }}</b-button
      >
    </div>
  </div>
</template>

<script>
import AxiosService from "../../axiosServices/AxiosService"
import GmapCustomMarker from "vue2-gmap-custom-marker"
// import VueGoogleAutocomplete from "vue-google-autocomplete"
export default {
  props: {
    idOrdine: Number,
    dataInizio: String,
    dataFine: String,
    cieSelezionato: Number,
    dossierName: String,
  },
  components: {
    "gmap-custom-marker": GmapCustomMarker,
    // "vue-google-autocomplete": VueGoogleAutocomplete,
  },
  created() {
    if (this.idOrdine) {
      this.getOrdine()
    } else {
      this.tmpItem = {
        id: 0,
        idDossier: this.idDossier,
        Dossier: "",
        idDossierManager: this.$store.state.user.user.id,
        DossierManager:
          this.$store.state.user.user.cognome +
          " " +
          this.$store.state.user.user.nome,
        DossierManagerImage: this.$store.state.user.user.avatar_url,
        descrizioneDossier: "",
        Cliente: "",
        idCie: 0,
        Cie: "",
        codice: "",
        titolo: "",
        luogo: "",
        linkMaps: "",
        dataOra_inizio: this.dataInizio ? this.dataInizio : "",
        elementi: [],
        annotazioni: "",
        ora_suggerita: "08:00:00",
        numeroCie: 1,
        durataStimata: 0,
        confermato: false,
        rilevazioni: 0,
      }
    }

    if (this.dataInizio && this.dataFine) {
      this.dateDiffInDays(this.dataInizio, this.dataFine)
    }

    if (this.cieSelezionato) {
      this.tmpItem.idCie = this.cieSelezionato
    }

    this.getCIEOptions()
  },

  data() {
    return {
      markers: [
        {
          lat: 45.40334398498736,
          lng: 10.848341660757919,
        },
      ],
      CieOptions: [{ value: null, text: "CIE" }],
      loading: false,
      tmpItem: {
        id: 0,
        idDossier: 0,
        Dossier: "",
        idDossierManager: 0,
        DossierManager: "",
        DossierManagerImage: null,
        descrizioneDossier: "",
        Cliente: "",
        idCie: 0,
        Cie: "",
        codice: "",
        titolo: "",
        luogo: "",
        linkMaps: "",
        dataOra_inizio: "",
        ora_suggerita: "",
        elementi: [],
        annotazioni: "",
        numeroCie: 1,
        durataStimata: 0,
        confermato: false,
        rilevazioni: 0,
      },
      address: "",
    }
  },

  methods: {
    getAddressData: function(addressData, placeResultData, id) {
      this.address = addressData
    },
    addMarker(event) {
      this.markers.push({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      })
    },
    dateDiffInDays(date1, date2) {
      const oneDay = 24 * 60 * 60 * 1000 // One day in milliseconds
      const firstDate = new Date(date1)
      const secondDate = new Date(date2)
      const diffInMs = Math.abs(firstDate - secondDate) // Difference in milliseconds
      this.tmpItem.durataStimata = Math.round(diffInMs / oneDay)
    },
    getOrdine() {
      const service = new AxiosService(
        "DossierManager/GetOrdineDiServizio/" + this.idOrdine
      )

      service.read().then((res) => {
        console.log(
          "🚀 ~ file: sbOrdineDiServizio.vue:186 ~ service.read ~ res:",
          res
        )
        this.tmpItem = res
      })
    },
    saveItem() {
      this.loading = true

      let nuoviSelezionati = []
      // this.elementiSelezionati.forEach((selezionato) => {
      //   this.dossier.elementi.forEach((elemento) => {
      //     if (elemento.id == selezionato) {
      //       nuoviSelezionati.push({
      //         id: elemento.id,
      //         tag: elemento.tag,
      //         descrizione: elemento.descrizione,
      //         allegati: elemento.allegati,
      //         delete: false,
      //       })
      //     }
      //   })
      //   this.tmpItem.elementi = nuoviSelezionati
      // })

      if (this.tmpItem.id) {
        console.log("PUT")
        const service = new AxiosService("DossierManager/EditOrdineDiServizio")

        this.tmpItem.elementi
        service
          .update(this.tmpItem)
          .then((res) => console.log(res))
          .finally(() => {
            this.sidebarOrdineVisible = false
            this.getData()
            this.loading = false
          })
      } else {
        console.log("POST")
        const service = new AxiosService(
          "DossierManager/AddOrdineDiServizio/" + this.$route.params.idDossier
        )
        service
          .create(this.tmpItem)
          .then((res) => console.log(res))
          .finally(() => {
            this.sidebarOrdineVisible = false
            this.getData()
            this.loading = false
          })
      }
    },
    getCIEOptions() {
      const service = new AxiosService("User")
      service.read().then((res) => {
        res.forEach((element) => {
          this.CieOptions.push({
            value: element.id,
            text: element.nome + " " + element.cognome,
          })
        })
      })
    },
  },
}
</script>
